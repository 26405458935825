import { lazy } from "react";

const PagesRoutes = [
  // COMPONENTS
  {
    path: "/search-groups",
    component: lazy(() => import("../../view/pages/black-list")),
    layout: "VerticalLayout",
  },
  {
    path: "/search-jobs",
    component: lazy(() => import("../../view/pages/search-jobs")),
    layout: "VerticalLayout",
  },
  {
    path: "/link-details/:id",
    component: lazy(() => import("../../view/pages/link-details")),
    layout: "VerticalLayout",
  },
];

export default PagesRoutes;