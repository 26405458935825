import {Code, GlobalSearch} from 'iconsax-react';

import IntlMessages from "../../layout/components/lang/IntlMessages";

const inUse = [
    {
        header: <IntlMessages id="sidebar-in-use" />,
    },
    {
        id: "black-list",
        title: <IntlMessages id="sidebar-pages-black-list-page" />,
        icon: <Code size={18} />,
        navLink: "/search-groups",
    },
    {
        id: "search-jobs",
        title: <IntlMessages id="sidebar-pages-search-jobs" />,
        icon: <GlobalSearch size={18} />,
        navLink: "/search-jobs",
    }
];

export default inUse