import { createSlice } from '@reduxjs/toolkit';
import {getSearchGroups} from "./searchGroupActions";

const initialState = {
    groups: [],
};

export const searchGroupReducer = createSlice({
    name: 'SEARCH GROUPS',
    initialState,

    reducers: {
    },
    extraReducers: {
        [getSearchGroups.pending]: (state) => {
            state.status = true;
        },
        [getSearchGroups.fulfilled]: (state, { payload }) => {
            state.groups = payload.data;
            state.status = false;
        },
        [getSearchGroups.rejected]: (state) => {
            state.status = false;
        },
    },
});

export const {} = searchGroupReducer.actions;

export default searchGroupReducer.reducer;
