import { combineReducers } from '@reduxjs/toolkit';

import calendarReducer from "./calendar/calendarReducer";
import contactReducer from "./contact/contactReducer";
import customiseReducer from "./customise/customiseReducer";
import ecommerceReducer from "./ecommerce/ecommerceReducer";
import country from "./country/countryReducer";
import countryGroupReducer from "./countryGroups/countryGroupReducer";
import blackListReducer from "./blackList/blackListReducer";
import searchJobs from "./searchJobs/searchJobReducer";
import searchEngines from "./searchEngines/searchEnginesReducer";
import linkDetails from "./linkDetails/linkDetailsReducer";
import auth from "./auth/authReducer";
import searchGroupReducer from "./searchGroups/searchGroupReducer";

const rootReducer = combineReducers({
  calendar: calendarReducer,
  contact: contactReducer,
  ecommerce: ecommerceReducer,
  customise: customiseReducer,
  country: country,
  countryGroups: countryGroupReducer,
  blackList: blackListReducer,
  searchJobs: searchJobs,
  searchEngines: searchEngines,
  linkDetails: linkDetails,
  auth: auth,
  searchGroups: searchGroupReducer
});

export default rootReducer;