import { createAsyncThunk } from '@reduxjs/toolkit';
import Repository, {apiUrl} from '../../repository/Repository';

export const getSearchGroups = createAsyncThunk('SEARCH_GROUPS/getSearchGroups', async () => {
    return await Repository.get(`${apiUrl}/search-group/read`);
});

export const createSearchJob = createAsyncThunk('SEARCH_GROUPS/createSearchJob', async (data) => {
    return await Repository.post(`${apiUrl}/search-group/create`, data);
});

export const addJobToGroup = createAsyncThunk('SEARCH_GROUPS/addJobToGroup', async (data) => {
    return await Repository.put(`${apiUrl}/search-group/add-jobs?id=${data.id}&search_job_ids=${data.search_job_ids}`);
});

export const addImageToJob = createAsyncThunk('SEARCH_GROUPS/addJobToGroup', async (data) => {
    const formData = new FormData();
    formData.append("files", data.files);
    return await Repository.post(`${apiUrl}/search-job/upload-image/${data.id}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
});

export const deleteGroupJob = createAsyncThunk('SEARCH_GROUPS/deleteGroupJob', async (data) => {
    return await Repository.delete(`${apiUrl}/search-group/delete?id=${data.id}`, data);
});
