import { createAsyncThunk } from '@reduxjs/toolkit';
import Repository, {apiUrl, serializeQuery} from '../../repository/Repository';
import {error, success} from "../../error/Error";

export const getLinkDetailsAdmin = createAsyncThunk('LINK_DETAILS/getLinkDetailsAdmin', async (data) => {
    return await Repository.get(`${apiUrl}/search-job-link/read-by-search-job/${data.search_job_id}${serializeQuery(data.params)}`).then((res) => res);
});

export const getLinkDetailsGroups = createAsyncThunk('LINK_DETAILS/getLinkDetailsGroups', async (data) => {
    return await Repository.get(`${apiUrl}/search-job-link/read-by-search-group/${data.search_job_id}${serializeQuery(data.params)}`).then((res) => res);
});

export const getSearchJobStatus = createAsyncThunk('LINK_DETAILS/getLinkDetailsAdmin', async (data) => {
    return await Repository.get(`${apiUrl}/export/search_job/read/status/${data}`);
});

export const getSearchJobByID = createAsyncThunk('LINK_DETAILS/getLinkDetailsAdmin', async (data) => {
    return await Repository.get(`${apiUrl}/search_job/read/${data}`);
});

export const setSearchJobStatus = createAsyncThunk('LINK_DETAILS/getLinkDetailsAdmin', async (data) => {
    return await Repository.put(`${apiUrl}/search_job/update-status`, data).then(() => {
        success('No valid results set!');
    }).catch(() => {
        error("Failed, something went wrong!");
    });
});

export const setDataForSending = createAsyncThunk('LINK_DETAILS/setDataForSending', async (data) => {
    return await Repository.put(`${apiUrl}/search-job-link/bulk-update-for-sending`, data).then(() => {
        success('Set for Sending!');
    }).catch(() => {
        error("Sending failed, something went wrong!");
    });
});

export const addNewLink = createAsyncThunk('LINK_DETAILS/addNewLink', async (data) => {
    return await Repository.post(`${apiUrl}/search-job-link/add`, data);
});

export const generateResults = createAsyncThunk('LINK_DETAILS/generateResults', async (data) => {
    return await Repository.post(`${apiUrl}/link_details/generate/results`, data);
});
